
:root {
    --cbmis-dark:#201e1d;
    --cbmis-light:#F6F4F2;
    --cbmis-primary: #B08730;
    --cbmis-secondary: #EAE3DF;
}
body{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    color: var(--cbmis-secondary) !important;
    margin: auto !important;
    background-color: var(--cbmis-dark) !important;
    background: #201e1d important;
    background: linear-gradient(90deg, rgba(32,30,29,1) 0%, rgba(39,35,35,1) 100%) !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


/* shared */
.cbmis-typography{
    text-align: justify !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif !important; */

}
.cbmis-typography-h4{
    text-align: center !important;
    font-family: Mada, sans-serif !important;
    font-weight: 600 !important;
    color: var(--cbmis-primary) !important;
    font-size: 2rem !important;
}

.accordionTab_landingPage .cbmis-accordion-tabs{
    border-radius: 0 0 8px 8px !important;
    -webkit-border-radius: 0 0 8px 8px !important;
    -moz-border-radius: 0 0 8px 8px !important;
    -ms-border-radius: 0 0 8px 8px !important;
    -o-border-radius: 0 0 8px 8px !important;
    box-shadow: none !important;
    background: transparent !important;
}
.accordionTab_landingPage .cbmis-accordion-tabs .cbmis-accordion-tab-summary{
    box-shadow: none !important;
}

/* header */
.cbmis-app-bar-fixed{
    position: absolute !important;
    color: var(--cbmis-primary) !important;
    background-color: transparent !important;
    box-shadow: none !important;
    top: 0 !important;
}

.cbmis-app-bar-Limiter{
    top: -1px !important;
    color:  var(--cbmis-primary) !important;
    background-color:  var(--cbmis-dark) !important;
    border-bottom: 1px solid var(--cbmis-primary) !important;
}

  .cbmis-drawer{
    background-color: var(--cbmis-dark) !important;
    position: fixed!important;
    min-height: auto !important;
    box-shadow: none !important;
    color:var(--cbmis-light) !important;
    z-index: 200000 !important;
    max-width: 10vw !important;
    margin: 0 !important;
}


.cbmis-drawer-tollage{
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    background-color: var(--cbmis-dark) !important;
    opacity: .5 !important;
}


.cbmis-drawer .cbmis-groupBar::after{
    border-bottom:  0px solid transparent !important;
}

.cbmis-app-bar-nav .cbmis-app-bar-nav-sub-menu{
    background-color: var(--cbmis-dark) !important;
}
.cbmis-app-bar-nav .cbmis-app-bar-nav-sub-menu li{
    border-bottom:  1px solid #000 !important;
    font-size: 18px !important;
}
.cbmis-drawer  .cbmis-app-bar-nav .cbmis-app-bar-nav-sub-menu li span{
    padding-inline-start: 5px !important;
    font-size: 18px !important;
    text-align: start !important;
}

/* toolbox */
.CbmisIconContainer {
    width: 24px !important;
    height: 24px !important;
 }

.cbmis-icon{
    display: block !important;
    margin: auto !important;
 }

 #cbmis-switch-square-1 .cbmis-switch-knobs, #cbmis-switch-square-1 .cbmis-switch-knobs:before, #cbmis-switch-square-1 .cbmis-switch-layer {
    transition: 0.3s ease all;
}
#cbmis-switch-square-1 .cbmis-switch-knobs:before {
    background: var(--cbmis-primary) !important;
}

#projectManagement{
    background-image: url("../../assets/images/pmBg.png") !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100% !important;
    border-block: 1px solid var(--cbmis-primary) !important;

}

#projectManagement::before, #educationalServices::before{
    content: "";
    background-color: var(--cbmis-dark);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0; left: 0;
    opacity: .4;
}


#educationalServices{
    background-image: url("../../assets/images/cuberBg.jpg") !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100% !important;
    border-block: 1px solid var(--cbmis-primary) !important;

}


.boxConsultationServices{
    background-position: center right !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.boxConsultationServices::before{
    content: "";
    background-color: var(--cbmis-dark);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0; left: 0;
    opacity: .6;
}

.cbmisToTopButton{
    z-index: 10000 !important;
    background-color:var(--cbmis-secondary) !important ;
    border-color:var(--cbmis-primary)!important;
    color: var(--cbmis-primary)!important
  }
  .cbmisToTopButton .CbmisIconContainer{
    color: var(--cbmis-primary)!important
  }

  .arbLang{
    left: 30px !important;
    right: auto !important;
  } 

  .cbmis-ParallaxScrolling-container{
    margin: 0 !important;
  } 

.cbmis-ParallaxScrolling-image{
    background-position-y: bottom !important;
    background-position-x: center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
  }

  #cbmis-switch-square-1 .cbmis-switch-checkbox:checked ~ .cbmis-switch-layer{
    opacity: 1 !important;
    background-color:#222!important;
  }

.langEng .cbmis-switch-layer::after{
    content: "عربي";
    font-size: 11px !important;
    width: 100% !important;
    display: block !important;
    position: absolute;
    text-align: right !important;
    right: 3px !important;
    top:5px !important;
    color:#F6F4F2 !important
  } 
  .langArb .cbmis-switch-layer::after{
    content: "En";
    font-size: 11px !important;
    width: 100% !important;
    display: block !important;
    position: absolute;
    text-align: left !important;
    left: 3px !important;
    top:5px !important;
   color:#F6F4F2 !important
  } 

  .cbmis-modal-header .cbmis-typography{
    text-align: center !important;
  }